<div class="container" style="margin-top: 100px;">
  <div class="d-flex justify-content-center h-50">
      <div class="user_card">
        <div class="d-flex justify-content-center">
          <div style="margin-top: 10px;margin-bottom:-90px;">
            <h3>User Management Portal</h3>
          </div>
        </div>
        <div class="d-flex justify-content-center form_container">
          <form #registerForm="ngForm" (ngSubmit)="onRegister(registerForm.value)">
            <div class="input-group mb-3">
              <div class="input-group-append">
                <span class="input-group-text"><i class="fas fa-user"></i></span>
              </div>
              <input type="text" class="form-control" name="firstName" placeholder="First name" required ngModel #firstNameInput="ngModel">
            </div>
            <span class="help-block" *ngIf="firstNameInput.invalid && firstNameInput.touched" style="color:red;">Please enter a first name</span>
            <div class="input-group mb-3">
              <div class="input-group-append">
                <span class="input-group-text"><i class="fas fa-user"></i></span>
              </div>
              <input type="text" class="form-control" name="lastName" placeholder="Last name" required ngModel #lastNameInput="ngModel">
            </div>
            <span class="help-block" *ngIf="lastNameInput.invalid && lastNameInput.touched" style="color:red;">Please enter a last name</span>
            <div class="input-group mb-3">
              <div class="input-group-append">
                <span class="input-group-text"><i class="fas fa-id-card"></i></span>
              </div>
              <input type="text" class="form-control" name="username" placeholder="Username" required ngModel #usernameInput="ngModel">
            </div>
            <span class="help-block" *ngIf="usernameInput.invalid && usernameInput.touched" style="color:red;">Please enter a username</span>
            <div class="input-group mb-2">
              <div class="input-group-append">
                <span class="input-group-text"><i class="fas fa-envelope"></i></span>
              </div>
              <input type="text" class="form-control" name="email" placeholder="Email" required ngModel #emailInput="ngModel">
            </div>
            <span class="help-block" *ngIf="emailInput.invalid && emailInput.touched" style="color:red;">Please enter an email.</span>
        <div class="d-flex justify-content-center mt-3 login_container">
          <button [disabled]="registerForm.invalid || showLoading" type="submit" name="button" class="btn login_btn">
            <i *ngIf="showLoading" class="fas fa-spinner fa-spin"></i>&nbsp;&nbsp;
            <span *ngIf="showLoading">Loading...</span>
            <span *ngIf="!showLoading">Register</span>
          </button>
        </div>
      </form>
    </div>
        <div class="mt-4">
          <div class="d-flex justify-content-center links">
            Already have an account? <a routerLink="/login"  class="ml-2" style="color: #2C3E50;">Log In</a>
          </div>
        </div>
      </div>
    </div>
</div>
